<template>
  <div>
    <a-form-model-item prop="sub_batch_out" :label="$t('容器')">
      <a-select
        :value="selectedSubBatchItem.id"
        :disabled="!location"
        show-search
        allowClear
        style="width: 100%"
        :dropdownMatchSelectWidth="false"
        @change="changeSubBatch"
      >
        <a-select-option v-for="item in dataItems" :key="item.id" :value="item.id" :item="item">
          {{ item.pallet_number }} | {{ item.status_display }}
          <span v-if="item.production_date"> | {{ item.production_date }}</span>
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item :label="$t('货品编号')">
      <a-input :value="selectedSubBatchItem.material_number" disabled />
    </a-form-model-item>
    <a-form-model-item :label="$t('货品名称')">
      <a-input :value="selectedSubBatchItem.material_name" disabled />
    </a-form-model-item>
    <a-form-model-item :label="$t('批次编号')">
      <a-input :value="selectedSubBatchItem.batch_number" disabled />
    </a-form-model-item>
  </div>
</template>

<script>
import { subBatchOption } from "@/api/option";

export default {
  props: ["value", "location"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      dataItems: [],
      selectedSubBatchItem: {},
    };
  },
  methods: {
    filterOption(inputValue, option) {
      const item = option.data.attrs.item;
      if (item.number.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) {
        return true;
      }

      if (item.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) {
        return true;
      }

      if (item.barcode && item.barcode.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) {
        return true;
      }

      item.number.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
      return false;
    },
    getData() {
      subBatchOption({ location: this.location, has_stock: true, is_pallet: true, page_size: 999999 }).then((data) => {
        this.dataItems = data.results;
      });
    },
    changeSubBatch(value, option) {
      this.selectedSubBatchItem = option?.data.attrs.item || {};
      this.$emit("change", value);
      this.$emit("select", this.selectedSubBatchItem);
    },
    resetData() {
      this.dataItems = [];
      this.selectedSubBatchItem = {};
    },
  },
  watch: {
    location(value) {
      if (value) {
        this.getData();
      }

      this.resetData();
    },
  },
};
</script>

<style scoped></style>
